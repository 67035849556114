import "./ActivitiesOverview.css";
import {
  Activity,
  activityAttributes,
  ActivityClass,
  activityConverter,
} from "./Types";
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import { ChangeEvent, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { auth, db } from "./FirebaseConfig";
import { Navigate } from "react-router-dom";
import { getYesterdayAsTimestamp } from "./TimeHelpers";

const ActivitiesOverview = () => {
  const isSignedIn = !!auth.currentUser;
  const [allActivities, setAllActivities] = useState<Activity[]>([]);
  const [newActivity, setNewActivity] = useState<Activity>({});

  useEffect(() => {
    if (isSignedIn) {
      const activitiesRef = collection(db, "activities");
      const activitiesQuery = query<Activity, Activity>(
        activitiesRef.withConverter(activityConverter),
        orderBy("randomizer"),
      );
      getDocs(activitiesQuery).then((snapshot) => {
        const tmpActivities: Activity[] = [];
        snapshot.forEach((value) => tmpActivities.push(value.data()));
        setAllActivities(tmpActivities);
      });
    }
  }, [isSignedIn]);

  // @ts-ignore
  const addNewActivity = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && newActivity.en && newActivity.de) {
      const newActivityWithDefaultValues = {
        randomizer: uuidV4(),
        lastUsed: getYesterdayAsTimestamp(),
        wasUsed: false,
        ...newActivity,
      };
      addDoc(
        collection(db, "activities").withConverter(activityConverter),
        newActivityWithDefaultValues,
      ).then((docRef) => {
        allActivities.push({ ...newActivityWithDefaultValues, id: docRef.id });
        const enNewInput = document.getElementById("newEnDescription");
        enNewInput && enNewInput.focus();
        setAllActivities(allActivities);
        setNewActivity({});
      });
    }
  };

  const updateActivity =
    (attr: string, index: number) =>
    // @ts-ignore
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const activity = ActivityClass.removeUndefinedFields(
          allActivities[index],
        );
        setDoc(
          // @ts-ignore
          doc(db, "activities", activity.id).withConverter(activityConverter),
          activity,
          { merge: true },
        ).then(() => {
          console.info("Success!!!!!!!!!!!!!");
        });
      }
    };

  const updateNewActivityValues =
    (attr: string, converter?: (date: string) => Timestamp) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let value = event.currentTarget.value;
      if (event.target.type === "checkbox") {
        // @ts-ignore
        value = event.currentTarget.checked;
      }
      setNewActivity({
        ...newActivity,
        [attr]: converter ? converter(value) : value,
      });
    };

  const updateActivityValues =
    (attr: string, index: number, converter?: (date: string) => Timestamp) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let value = event.currentTarget.value;
      if (event.target.type === "checkbox") {
        // @ts-ignore
        value = event.currentTarget.checked;
      }
      // @ts-ignore
      allActivities[index][attr] = converter ? converter(value) : value;
      setAllActivities([...allActivities]);
    };

  function addMissingRandomizersAndLastUsed() {
    const batch = writeBatch(db);
    const yesterday = getYesterdayAsTimestamp();
    const updatedAllActivities = allActivities.map((activity) => {
      let fieldsToBeSet: Activity = {};
      let shouldBeUpdated = false;
      if (!activity.randomizer || activity.randomizer.length < 36) {
        fieldsToBeSet = { ...fieldsToBeSet, randomizer: uuidV4().toString() };
        shouldBeUpdated = true;
      }
      if (!activity.lastUsed) {
        fieldsToBeSet = { ...fieldsToBeSet, lastUsed: yesterday };
        shouldBeUpdated = true;
      }
      const updatedActivity = { ...activity, ...fieldsToBeSet };

      if (shouldBeUpdated) {
        // @ts-ignore
        const documentRef = doc(db, "activities", activity.id).withConverter(
          activityConverter,
        );
        batch.update<Activity, Activity>(documentRef, updatedActivity);
      }
      return updatedActivity;
    });

    batch.commit().then(() => setAllActivities(updatedAllActivities));
  }

  function removeIds() {
    const batch = writeBatch(db);
    allActivities.forEach((activity) => {
      // @ts-ignore
      const documentRef = doc(db, "activities", activity.id).withConverter(
        activityConverter,
      );
      batch.update<Activity, Activity>(documentRef, {
        ...activity,
        id: deleteField(),
      });
    });

    batch.commit().then(() => {
      console.info("removeIds!!!");
    });
  }

  return isSignedIn ? (
    <div style={{ maxWidth: "900px", margin: "1rem auto" }}>
      <table className="movieList">
        <thead>
          <tr>
            {["", ...activityAttributes].map((activityAttr) => (
              <td key={activityAttr}>{activityAttr}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span>{allActivities.length + 1}</span>
            </td>
            <td>
              <span>{newActivity.id}</span>
            </td>
            <td>
              <input
                id="newEnDescription"
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("en")}
                value={newActivity.en ?? ""}
              />
            </td>
            <td>
              <input
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("de")}
                value={newActivity.de ?? ""}
              />
            </td>
            <td>
              <input
                type="checkbox"
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("wasUsed")}
                defaultChecked={newActivity.wasUsed}
              />
            </td>
            <td>
              <input
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("randomizer")}
                value={newActivity.randomizer ?? ""}
              />
            </td>
            <td>
              <input
                type="date"
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues(
                  "lastUsed",
                  (yyyyMMdd: string) => Timestamp.fromDate(new Date(yyyyMMdd)),
                )}
                value={
                  newActivity.lastUsed?.toDate().toISOString().split("T")[0] ??
                  ""
                }
              />
            </td>
            <td>
              <input
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("imageLink")}
                value={newActivity.imageLink ?? ""}
              />
            </td>
            <td>
              <input
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("alt")}
                value={newActivity.alt ?? ""}
              />
            </td>
            <td>
              <input
                onKeyPress={addNewActivity}
                onChange={updateNewActivityValues("externalContentLink")}
                value={newActivity.externalContentLink ?? ""}
              />
            </td>
            <td>
              <span> </span>
            </td>
          </tr>

          {allActivities.map((activity, index) => {
            return (
              <tr key={`activity${index}`}>
                <td>
                  <span>{index + 1}</span>
                </td>
                <td>
                  <span>{activity.id}</span>
                </td>
                <td>
                  <input
                    onKeyPress={updateActivity("en", index)}
                    onChange={updateActivityValues("en", index)}
                    value={activity.en}
                  />
                </td>
                <td>
                  <input
                    onKeyPress={updateActivity("de", index)}
                    onChange={updateActivityValues("de", index)}
                    value={activity.de}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    onKeyPress={updateActivity("wasUsed", index)}
                    onChange={updateActivityValues("wasUsed", index)}
                    defaultChecked={activity.wasUsed}
                  />
                </td>
                <td>
                  <input
                    onKeyPress={updateActivity("randomizer", index)}
                    onChange={updateActivityValues("randomizer", index)}
                    value={activity.randomizer ?? ""}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    onKeyPress={updateActivity("lastUsed", index)}
                    onChange={updateActivityValues(
                      "lastUsed",
                      index,
                      (yyyymmdd: string) =>
                        Timestamp.fromDate(new Date(yyyymmdd)),
                    )}
                    value={
                      activity.lastUsed?.toDate().toISOString().split("T")[0] ??
                      ""
                    }
                  />
                </td>
                <td>
                  <input
                    onKeyPress={updateActivity("imageLink", index)}
                    onChange={updateActivityValues("imageLink", index)}
                    value={activity.imageLink ?? ""}
                  />
                </td>
                <td>
                  <input
                    onKeyPress={updateActivity("alt", index)}
                    onChange={updateActivityValues("alt", index)}
                    value={activity.alt ?? ""}
                  />
                </td>
                <td>
                  <input
                    onKeyPress={updateActivity("externalContentLink", index)}
                    onChange={updateActivityValues(
                      "externalContentLink",
                      index,
                    )}
                    value={activity.externalContentLink ?? ""}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <input
        type="button"
        value="Add Missing Randomizer and Dates"
        onClick={addMissingRandomizersAndLastUsed}
      />
      <br />
      <br />
      <br />

      <input type="button" value="Remove ids" onClick={removeIds} />
      <br />
      <br />
      <br />

      {/* <input type="button" value="Reset wasUsed and lastUsed"
                      onClick={resetWasUsedAndLastUsed}/>
                <br/><br/><br/> */}
    </div>
  ) : (
    <Navigate to="/signin" replace={true} state="/activities" />
  );
};

export default ActivitiesOverview;
