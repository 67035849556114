import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "./FirebaseConfig";
import { Activity, activityConverter } from "./Types";
import { v4 as uuidV4 } from "uuid";
import {
  createTimestampOfTodayAt0AM,
  getYesterdayAsTimestamp,
} from "./TimeHelpers";

const collectionRef = collection(db, "activities").withConverter(
  activityConverter,
);

export const fetchTodayActivities = async () => {
  const todayAt0AM: Timestamp = createTimestampOfTodayAt0AM();
  const todayActivitiesQuery = query<Activity, Activity>(
    collectionRef,
    where("lastUsed", ">=", todayAt0AM),
    limit(3),
  );
  const snapshot = await getDocs<Activity, Activity>(todayActivitiesQuery);
  const tmpActivities: Activity[] = [];
  snapshot.forEach((value) => tmpActivities.push(value.data()));
  return tmpActivities;
};

export const fetchNextDailyActivities = async (): Promise<Activity[]> => {
  const activitiesQuery = query<Activity, Activity>(
    collectionRef,
    where("wasUsed", "==", false),
    orderBy("randomizer"),
    limit(3),
  );
  const snapshot = await getDocs<Activity, Activity>(activitiesQuery);
  const fetchedActivities: Activity[] = [];
  snapshot.forEach((value) => fetchedActivities.push(value.data()));
  return fetchedActivities;
};

export const updateStandardFieldsOf = (activities: Activity[]) => {
  const todayAt0AM: Timestamp = createTimestampOfTodayAt0AM();
  const updatedActivities: Activity[] = [];
  const batch = writeBatch(db);
  activities.forEach((activity) => {
    const fieldsToBeUpdated: Activity = {
      lastUsed: todayAt0AM,
      randomizer: uuidV4(),
      wasUsed: true,
    };
    const updatedActivity: Activity = { ...activity, ...fieldsToBeUpdated };
    updatedActivities.push(updatedActivity);
    // @ts-ignore
    const documentRef = doc(db, "activities", activity.id).withConverter(
      activityConverter,
    );
    batch.update<Activity, Activity>(documentRef, fieldsToBeUpdated);
  });
  batch.commit().then(() => {
    console.info("Successful batch update!");
  });

  return updatedActivities;
};

export async function resetWasUsedAndLastUsed() {
  const activitiesQuery = query<Activity, Activity>(collectionRef);
  const allActivities: Activity[] = [];
  const snapshot = await getDocs<Activity, Activity>(activitiesQuery);
  snapshot.forEach((value) => allActivities.push(value.data()));

  const batch = writeBatch(db);
  const yesterday = getYesterdayAsTimestamp();
  const todayAt0AM = createTimestampOfTodayAt0AM();
  const resetValues = { wasUsed: false, lastUsed: yesterday };
  allActivities.forEach((activity) => {
    if (
      activity.wasUsed ||
      !activity.lastUsed ||
      activity.lastUsed >= todayAt0AM
    ) {
      // @ts-ignore
      const documentRef = doc(db, "activities", activity.id).withConverter(
        activityConverter,
      );
      batch.update<Activity, Activity>(documentRef, resetValues);
    }
  });

  await batch.commit();
  console.info("resetWasUsedAndLastUsed!!!");
}
