import React, { ChangeEvent, useState } from "react";

import "./i18n";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Link, Route, Routes } from "react-router-dom";
import DailyActivities from "./DailyActivities";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import { db, isEmulatorRunning } from "./FirebaseConfig";
import ActivitiesOverview from "./ActivitiesOverview";
import SignInScreen from "./SignInScreen";
import BlogPost from "./blog/articles/UnderstandingNegation";
import "./App.css";

function App() {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  let [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  function showFeedbackBubble() {
    if (!isFeedbackOpen) {
      const feedbackModal = document.getElementById("feedback-modal");

      if (feedbackModal) {
        feedbackModal.style.right = -250 + "px";
        feedbackModal.style.bottom = -100 + "px";
      }
    }
  }

  function hideFeedbackBubble() {
    if (!isFeedbackOpen) {
      const feedbackModal = document.getElementById("feedback-modal");
      if (feedbackModal) {
        feedbackModal.style.right = -300 + "px";
        feedbackModal.style.bottom = -110 + "px";
      }
    }
  }

  function toggleFeedbackBubble() {
    const feedbackModal = document.getElementById("feedback-modal");
    const footerHeight = document.getElementById("footer")?.offsetHeight ?? 0;
    if (!feedbackModal) {
      return;
    }
    if (!isFeedbackOpen) {
      feedbackModal.style.right = -10 + "px";
      feedbackModal.style.bottom = +footerHeight - 10 + "px";

      setIsFeedbackOpen(true);
    } else {
      feedbackModal.style.right = -300 + "px";
      feedbackModal.style.bottom = -110 + "px";

      setIsFeedbackOpen(false);

      showFeedbackBubble();
    }
  }

  function saveFeedback() {
    const hiddenInput: HTMLInputElement =
      (document.getElementById("feedbackCheck") as HTMLInputElement) ??
      new HTMLInputElement();
    if (hiddenInput && !hiddenInput.value && feedback !== "") {
      addDoc(collection(db, "feedbacks"), {
        feedback: feedback,
        createdAt: Timestamp.fromDate(new Date()),
      }).then(() => {
        console.info("Success!!!!!!!!!!!!!");
      });
      alert(t("thanksForFeedback"));
    }
    setFeedback("");
    toggleFeedbackBubble();
  }

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setFeedback(event.currentTarget.value);
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<DailyActivities />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/blog" element={<BlogPost />} />
        {isEmulatorRunning && (
          <Route path="/activities" element={<ActivitiesOverview />} />
        )}
        {isEmulatorRunning && (
          <Route path="/signin" element={<SignInScreen />} />
        )}
      </Routes>
      <div className="feedback-modal" id="feedback-modal">
        <div id="close-modal" onClick={toggleFeedbackBubble}>
          &#10005;
        </div>
        <form>
          <h3>{t("letUsKnowWhatYouThink")}</h3>
          <label className="feedback-text">
            <textarea value={feedback} onChange={handleChange} />
          </label>
          <input type="hidden" id="feedbackCheck" />
          <label className="feedback-send">
            <input type="button" onClick={saveFeedback} value={t("send")} />
          </label>
        </form>
      </div>
      <footer id="footer">
        <p>
          © Kids Activities &nbsp; &nbsp; <Link to="/terms">{t("terms")}</Link>{" "}
          &nbsp; &nbsp;
          <Link to="/privacy">{t("privacy")}</Link> &nbsp; &nbsp;
          <Link to="/imprint">{t("imprint")}</Link> &nbsp; &nbsp;
          <Link to="/blog">{t("blog")}</Link>
          <span
            className="feedback"
            onMouseOver={showFeedbackBubble}
            onMouseOut={hideFeedbackBubble}
            onClick={toggleFeedbackBubble}
          >
            &#128172; <span className="xs-hidden">{t("sendUsFeedBack")}</span>
          </span>
        </p>
      </footer>
    </>
  );
}

export default App;
